<template>

<div class="context">

	<div v-if="canSwitch" v-on:click="onSwitchClick" class="context-avatar" :style="style" v-tooltip="'Click to switch guide'">
		{{ initials }}
	</div>

	<div v-if="!canSwitch" class="context-avatar is-dead" :style="style">
		{{ initials }}
	</div>

</div>

</template>

<script>

export default {

	computed: {

		canSwitch: function() {
			
			return (this.$store.getters['user']) ? this.$store.getters['user'].guides > 1 : false

		},

		initials: function() {

			return this.$store.getters['guide'].initials

		},

		style: function() {

			return {
				color: '#' + this.$store.getters['guide'].color.fore,
				backgroundColor: '#' + this.$store.getters['guide'].color.back
			}

		}

	},

	methods: {

		onSwitchClick: function() {

			this.$router.push({
				name: 'Dashboard'
			})

		}

	}

}

</script>

<style scoped>

.context {
	width: 100%;
	margin-bottom: 5px;
}

.context-avatar {
	width: 54px;
	height: 54px;
	margin: 5px;
	background-color: #fff;
	border-radius: 50%;
	line-height: 58px;
	text-align: center;
	font-weight: bold;
	font-size: 24px;
	letter-spacing: 1px;
	color: #000;
	transition: all 100ms linear;
}

.context-avatar:not(.is-dead):hover {
	transform: scale(1.05);
	cursor: pointer;
}

</style>
